@import '/src/css/style.css';
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* background-color: #f1f1f1; */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root {
	--amplify-primary-color: #1aa9e2;
	--amplify-primary-tint: #5ebfe6;
	--amplify-primary-shade: #5ebfe6;
	/* --amplify-tertiary-contrast: #1aa9e2;
	--amplify-tertiary-color: #1aa9e2;
	--amplify-tertiary-tint: #1aa9e2; */
	/* --amplify-tertiary-shade: #1aa9e2; */
	/* --amplify-font-family: 'Roboto', sans-serif; */

	/* --amplify-secondary-tint: #5ebfe6; */
}

/* .button:hover {
	--amplify-primary-color: #1aa9e2 !important;
	color: #1aa9e2 !important;
} */
